import { Component, EventEmitter, Input, Output, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatListModule, MatListOption } from '@angular/material/list';
import { RxAfterViewInit } from '@examdojo/angular/util';
import { SelectOption } from '@examdojo/util/select-option';
import { TranslocoPipe } from '@jsverse/transloco';
import { delay, filter, Observable, tap } from 'rxjs';

@RxAfterViewInit()
@Component({
  selector: 'dojo-flat-select',
  standalone: true,
  imports: [MatListModule, ReactiveFormsModule, TranslocoPipe],
  template: `
    <mat-selection-list
      class="custom-selection-list"
      [formControl]="formCtrl"
      name="options"
      (selectionChange)="optionSelected.emit($event.options)"
      [multiple]="multiple"
      [class.row-wrap]="layout === 'row'"
    >
      @for (option of options; track option.value) {
        <mat-list-option [value]="option.value">
          @if (labelsAreTranslationKeys) {
            {{ option.label | transloco }}
          } @else {
            {{ option.label }}
          }
        </mat-list-option>
      }
    </mat-selection-list>
  `,
  styleUrl: './flat-select.component.scss',
})
export class FlatSingleSelectComponent {
  constructor() {
    this.setInitialFocus().pipe(takeUntilDestroyed()).subscribe();
  }

  @Input({ required: true }) formCtrl!: FormControl;
  @Input({ required: true }) options: Array<SelectOption<string | number>> = [];
  @Input() labelsAreTranslationKeys = false;
  @Input() hasInitialFocus = false;
  @Input() multiple = false;
  @Input() layout: 'row' | 'column' = 'column';

  @Output() optionSelected = new EventEmitter<MatListOption[]>();

  private readonly matListOption = viewChild<MatListOption>(MatListOption);

  readonly ngAfterViewInit$!: Observable<void>;

  setFocus() {
    this.matListOption()?.focus();
  }

  private setInitialFocus() {
    return this.ngAfterViewInit$.pipe(
      filter(() => !!this.hasInitialFocus),
      delay(0),
      tap(() => this.setFocus()),
    );
  }
}
