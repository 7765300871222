export enum UserOriginType {
  Recommendation = 'recommendation',
  SocialMedia = 'social_media',
  AppStore = 'app_store',
  Other = 'other',
}

export enum RecommendationType {
  Tutor = 'tutor',
  Teacher = 'teacher',
  Friend = 'friend',
  Parent = 'parent',
}

export enum SocialMedia {
  TikTok = 'TikTok',
  Reddit = 'Reddit',
  Instagram = 'Instagram',
  Facebook = 'Facebook',
  LinkedIn = 'LinkedIn',
}

export type UserOriginDetail = RecommendationType | SocialMedia | string;

export const ORIGIN_TYPES_WITH_DETAILS = [
  UserOriginType.Recommendation,
  UserOriginType.SocialMedia,
  UserOriginType.Other,
] as const;

export type OriginTypeWithDetails = (typeof ORIGIN_TYPES_WITH_DETAILS)[number];
