import { ChangeDetectionStrategy, Component } from '@angular/core';
import { connectState } from '@examdojo/angular/util';
import { IconComponent } from '@examdojo/core/icon';
import {
  StepperSubmitFn,
  SwiperSlideDirective,
  SwiperStepperComponent,
  SwiperStepperNextDirective,
  SwiperStepperPreviousDirective,
} from '@examdojo/core/swiper';
import { ButtonComponent } from '@examdojo/ui/button';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { IonHeader, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { ImageComponent } from '@examdojo/ui/image';
import { UserQuery } from '../user/user.query';
import { APP_ONBOARDING_DIALOG_ID } from './app-onboarding-dialog.service';

@Component({
  selector: 'dojo-app-onboarding-dialog',
  standalone: true,
  imports: [
    IconButtonComponent,
    IonHeader,
    IonTitle,
    IonToolbar,
    TranslocoPipe,
    SwiperStepperComponent,
    SwiperSlideDirective,
    ButtonComponent,
    IconComponent,
    SwiperStepperNextDirective,
    SwiperStepperPreviousDirective,
    ImageComponent,
  ],
  templateUrl: './app-onboarding-dialog.component.html',
  styleUrl: './app-onboarding-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppOnboardingDialogComponent {
  constructor(
    private readonly modalController: ModalController,
    private readonly userQuery: UserQuery,
  ) {}

  readonly state = connectState({
    user: this.userQuery.active$,
  });

  readonly submitFn: StepperSubmitFn = async () => {
    this.closeDialog(true);
  };

  closeDialog(result = false) {
    this.modalController.dismiss(result, undefined, APP_ONBOARDING_DIALOG_ID);
  }
}
