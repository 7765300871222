import { ChatMessageModel } from '@examdojo/models/chat';
import { EntityState } from '@examdojo/state';
import * as uuid from 'uuid';
import { EngagementPhase } from './chat-phase.model';

// Cast type for ChatMessageHttpModel['message']: Json
export type ChatMessageHttpModelMessage = Array<{
  type: 'text' | 'image';
  content: string;
}>;

export type ChatMessageHttpModel = Omit<ChatMessageModel, 'message' | 'metadata'> & {
  message: ChatMessageHttpModelMessage;
  metadata?: {
    stem_id: number;
  };
};

export enum ChatMessageOrigin {
  Local = 'local',
  Remote = 'remote',
}

export interface ChatMessageContentStoreModel {
  text: string;
  images: string[];
}

interface BaseChatMessage
  extends Omit<
    ChatMessageHttpModel,
    'id' | 'output_tokens' | 'stop_reason' | 'input_tokens' | 'llm_chat_id' | 'message' | 'responds_to' | 'author'
  > {
  id: string;
  message: ChatMessageContentStoreModel;
  origin?: ChatMessageOrigin;
}

export interface UserMessageStoreModel extends BaseChatMessage {
  created_by_role: 'user';
}

export interface AgentRemoteMessageStoreModel extends BaseChatMessage {
  created_by_role: 'ai';
  origin: ChatMessageOrigin.Remote;
}

export interface AgentLocalMessageStoreModel extends BaseChatMessage {
  created_by_role: 'ai';
  origin: ChatMessageOrigin.Local;
}

export type ChatMessageStoreModel = UserMessageStoreModel | AgentRemoteMessageStoreModel | AgentLocalMessageStoreModel;

export type UserMessageUIModel = UserMessageStoreModel;

export type AgentRemoteMessageUIModel = AgentRemoteMessageStoreModel;

export type AgentLocalMessageUIModel = AgentLocalMessageStoreModel;

export type ChatMessageUIModel = UserMessageUIModel | AgentRemoteMessageUIModel | AgentLocalMessageUIModel;

export interface ChatMessageState
  extends EntityState<
    ChatMessageStoreModel,
    {
      savedLocalMessages: Record<ChatMessageStoreModel['id'], boolean>;
      engagementPhase: EngagementPhase | null;
      chatId: number | null;
      stemId: number | null;
    },
    ChatMessageStoreModel['id']
  > {}

function createMessageStoreModel(
  message: ChatMessageContentStoreModel,
  metadata: { stem_id: number },
  created_by_role: 'user',
  origin: ChatMessageOrigin.Local,
  created_at?: string,
): UserMessageStoreModel;
function createMessageStoreModel(
  message: ChatMessageContentStoreModel,
  metadata: { stem_id: number },
  created_by_role: 'ai',
  origin: ChatMessageOrigin.Local,
  created_at: undefined,
): AgentLocalMessageStoreModel;
function createMessageStoreModel(
  message: ChatMessageContentStoreModel,
  metadata: { stem_id: number },
  created_by_role: 'ai',
  origin: ChatMessageOrigin.Remote,
  created_at: string,
): AgentRemoteMessageStoreModel;
function createMessageStoreModel(
  message: ChatMessageContentStoreModel,
  metadata: { stem_id: number },
  created_by_role: BaseChatMessage['created_by_role'],
  origin?: ChatMessageOrigin,
  created_at?: string,
): BaseChatMessage {
  return {
    id: uuid.v4(),
    metadata,
    message,
    created_at: created_at ?? new Date().toISOString(),
    created_by_role,
    ...(origin && { origin }),
  };
}

export function createUserMessageStoreModel(
  message: ChatMessageContentStoreModel,
  stem_id: number,
): UserMessageStoreModel {
  return createMessageStoreModel(message, { stem_id }, 'user', ChatMessageOrigin.Local, undefined);
}

export function createAgentLocalMessageStoreModel(
  message: ChatMessageContentStoreModel,
  stem_id: number,
): AgentLocalMessageStoreModel {
  return createMessageStoreModel(message, { stem_id }, 'ai', ChatMessageOrigin.Local, undefined);
}

export function createInitialAgentRemoteMessageStoreModel(
  message: ChatMessageContentStoreModel,
  stem_id: number,
  created_at: string,
): AgentRemoteMessageStoreModel {
  return createMessageStoreModel(message, { stem_id }, 'ai', ChatMessageOrigin.Remote, created_at);
}
