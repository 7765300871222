import { Injectable } from '@angular/core';
import { DateTimeService } from '@examdojo/core/date-time';
import { UserState, UserStoreModel, UserUIModel } from '@examdojo/models/user';
import { QueryEntity } from '@examdojo/state';
import { distinctUntilChanged, filter, map, Observable } from 'rxjs';
import { UserStore } from './user.store';

@Injectable({ providedIn: 'root' })
export class UserQuery extends QueryEntity<UserState, UserStoreModel, UserUIModel, 'id'> {
  constructor(
    protected override store: UserStore,
    private readonly dateTimeService: DateTimeService,
  ) {
    super(store);
  }

  readonly daysUntilExam$ = this.active$.pipe(
    map((user) => user?.final_exam_date),
    map((rawExamDate) => {
      if (!rawExamDate) {
        return null;
      }

      const examDate = this.dateTimeService.parseDate(rawExamDate);
      if (!(examDate instanceof Date) || isNaN(examDate.getTime())) {
        return null;
      }

      return this.dateTimeService.diff(new Date(), examDate, 'days');
    }),
  );

  readonly isUserOnboarded$: Observable<boolean> = this.active$.pipe(
    filter(Boolean),
    map((user) => this.isUserOnboarded(user)),
    distinctUntilChanged(),
  );

  override readonly toUIModelFn = (entity: UserStoreModel): UserUIModel => {
    return {
      ...entity,
      fullName: `${entity.first_name} ${entity.last_name}`,
    };
  };

  isUserOnboarded(user: UserUIModel): boolean {
    return !!(
      user.first_name &&
      user.last_name &&
      user.programme &&
      user.school_name &&
      user.final_exam_date &&
      user.course_id &&
      user.origin
    );
  }
}
