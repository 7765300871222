import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { RootUrlParts } from '../app.model';
import { UserQuery } from '../user/user.query';

export const userOnboardingGuard: CanActivateFn = () => {
  const userQuery = inject(UserQuery);
  const router = inject(Router);

  return userQuery.isUserOnboarded$.pipe(
    map((isUserOnboarded) => {
      if (!isUserOnboarded) {
        return router.createUrlTree([`/${RootUrlParts.Onboarding}`]);
      }
      return true;
    }),
  );
};
