import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@examdojo/core/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { enableElfProdMode } from '@ngneat/elf';
import { LicenseManager } from 'ag-grid-enterprise';
import posthog from 'posthog-js';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

if (environment.production_build) {
  enableElfProdMode();
}

LicenseManager.setLicenseKey(
  'CompanyName=Datos-Intelligence GmbH,LicensedApplication=Datos,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-026970,ExpiryDate=15_May_2023_[v2]_MTY4NDEwNTIwMDAwMA==16880adeaae7008eb4e97ef84502e8cd',
);

if (environment.posthog.key) {
  posthog.init(environment.posthog.key, {
    api_host: environment.posthog.host,
    person_profiles: 'identified_only',
  });
}

defineCustomElements(window);

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
