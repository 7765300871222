import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { TranslocoPipe } from '@jsverse/transloco';
import { IconComponent } from '@examdojo/core/icon';
import { NgClass } from '@angular/common';
import { RootUrlParts } from '../../app.model';
import { FEATURE_TO_ICON, FEATURE_TO_LABEL_KEY, Features } from '../../features/features.model';
import { UserQuery } from '../../user/user.query';

@Component({
  selector: 'dojo-sidenav',
  standalone: true,
  imports: [RouterLink, TranslocoPipe, RouterLinkActive, IconButtonComponent, IconComponent, NgClass],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent {
  constructor(private readonly userQuery: UserQuery) {}

  @Input() isCompact?: boolean;

  readonly featurePages = [Features.LearnHub, Features.TopicPractice, Features.Assessments, Features.Leaderboards].map(
    (feature) => ({
      route: feature,
      label: FEATURE_TO_LABEL_KEY[feature],
      icon: FEATURE_TO_ICON[feature],
    }),
  );

  readonly state = connectState({
    user: this.userQuery.active$,
  });
  protected readonly RootUrlParts = RootUrlParts;
}
