export enum UserMessagePromptName {
  BigPicture = 'Big Picture',
  GiveMeAJumpstart = 'Give me a jumpstart',
  GiveMeTheTopics = 'Give me the topics',
  WhatAmIMissing = 'What am I missing?',
  StepByStep = 'Step-by-step',
  PriorKnowledge = 'Prior knowledge',
  MyAnswer = 'My answer',
}

const USER_PROMPT_SEPARATOR = '>>>>>>';

export const UserMessagesAliasesMap: Record<UserMessagePromptName, string> = {
  [UserMessagePromptName.BigPicture]: `You are tasked with providing a comprehensive explanation of how a given question 
  fits into the broader context of its field, identifying key concepts within the question, and providing real-world applications and 
  examples for each concept.

To explain where this question fits in the big picture:
1. Identify the general field or subject area the question belongs to.
2. Consider how this specific question relates to broader themes or topics within that field.
3. Think about why this question might be important or relevant in the context of the field.

Next, identify the key concepts present in the question. These may include:
- Specific terms or phrases
- Underlying principles or theories
- Methods or approaches mentioned or implied

For each concept you've identified:
1. Briefly explain what it means in the context of the field.
2. Provide at least one concrete example of how this concept is applied or observed in the real world.
3. If possible, mention any industries, professions, or areas of study where this concept is particularly relevant.

Ensure that your explanations are clear, concise, and accessible to a high school student. Use simple language where possible.`,
  [UserMessagePromptName.GiveMeAJumpstart]: `You are tasked with providing a jumpstart to help the student to begin solving this question. 
  Your goal is to analyze the given math question and provide a helpful starting point without solving the entire problem. 
  Based on your analysis, provide a helpful starting point for solving the problem. This should include:
1. A brief explanation of the problem type and relevant concepts
2. Identification of key information from the question
3. Suggestion of an initial step or approach to begin solving the problem
4. If applicable, mention any formulas or techniques that might be useful

Remember, your goal is to give the student a jumpstart without solving the entire problem for them. Offer guidance and direction, 
but leave room for the student to work through the problem independently.`,
  [UserMessagePromptName.GiveMeTheTopics]: `You are tasked with identifying the main topics of a given question. 
  Your goal is to provide a concise list of the key subjects or themes addressed in the question. Give some examples for each topic. 
  Aim for 1-3 topics, depending on the complexity of the question.`,
  [UserMessagePromptName.WhatAmIMissing]: `Your task is to help a student understand their mistakes, explain the underlying concepts, and provide context for how these concepts fit into the broader IB math curriculum and real-world applications.
Analyze the student's mistakes and provide a detailed explanation of the concepts they are missing. Be sure to:
1. Identify the specific errors in the student's work
2. Explain the correct mathematical principles and procedures
3. Provide step-by-step guidance on how to approach similar problems

Next, explain how these concepts fit into the broader context of the IB diploma math curriculum. Mention any prerequisites or related topics that the student should be familiar with.

Provide at least two real-world examples or applications of the mathematical concepts involved in this problem. This will help the student understand the practical relevance of what they're learning.
Ask them if they need more clarification on any of the points mentioned and give an example.
Remember to be encouraging and supportive in your explanations, emphasizing that mistakes are a natural part of the learning process.`,
  [UserMessagePromptName.StepByStep]: `You are an IB math diploma exam tutor. Your task is to provide a step-by-step breakdown of how to solve the given exam question and offer clarification if needed. Follow these instructions:

1. Begin by presenting the exam question:

2. Provide a step-by-step breakdown of the solution. Number each step clearly and explain the mathematical concepts and operations involved. Use LaTeX notation for mathematical expressions where appropriate, enclosing them in $ symbols (e.g., $x^2 + 3x - 2$). Use markdown to format the question in a nicely readable way.

3. After presenting all steps, summarize the solution and present the final answer.
4. Ask the student if they need clarification or a more detailed explanation ("Do you have any questions about the solution or need further clarification on any step?"). If the student asks for clarification, provide a more detailed explanation of the step, breaking it down further if necessary. Use examples or analogies when appropriate to aid understanding.


Remember to:
- Use clear and concise language appropriate for an IB math student.
- Explain any mathematical terms or concepts that may not be familiar to all students.
- Encourage the student to ask questions if they're unsure about any part of the solution.
- Be patient and supportive throughout the tutoring process.`,
  [UserMessagePromptName.PriorKnowledge]: `You are tasked with identifying the prior knowledge necessary to answer an IB Diploma math exam question. Your goal is to provide a concise list of topics that a student should be familiar with to successfully approach and solve the given question.

Carefully analyze the question and follow these steps:

1. Identify the main mathematical concepts and techniques required to solve the problem.
2. Consider any underlying principles or theories that the student should understand.
3. Think about any formulas, definitions, or mathematical properties that would be essential.
4. Reflect on any prerequisite skills or knowledge from earlier math courses that might be relevant.

Based on your analysis, create a short list of prior knowledge topics necessary to answer the question. Each topic should be concise but clear enough for a student to understand what they need to review.

After providing the list, ask the following question:

Do you need a more detailed explanation of any of these topics?

Remember to keep your list focused and relevant to the specific question at hand. Avoid including topics that are not directly related to solving the problem.`,
  [UserMessagePromptName.MyAnswer]: `Please show me my handwritten answer for this stem. Indicate if you had difficulties reading or interpreting my answer. If yes, suggest re-uploading the answer for a more correct grading.`,
};

export const PreGradingUserSuggestions = [
  UserMessagePromptName.BigPicture,
  UserMessagePromptName.GiveMeAJumpstart,
  UserMessagePromptName.GiveMeTheTopics,
].map((suggestionName) => ({
  label: suggestionName,
  value: `${UserMessagesAliasesMap[suggestionName]}${USER_PROMPT_SEPARATOR}${suggestionName}`,
}));

export const PostGradingUserSuggestions = [
  UserMessagePromptName.WhatAmIMissing,
  UserMessagePromptName.StepByStep,
  UserMessagePromptName.PriorKnowledge,
  UserMessagePromptName.MyAnswer,
].map((suggestionName) => ({
  label: suggestionName,
  value: `${UserMessagesAliasesMap[suggestionName]}${USER_PROMPT_SEPARATOR}${suggestionName}`,
}));

export function getUserPromptNameFromMessage(message: string): UserMessagePromptName | null {
  const parts = message.split(USER_PROMPT_SEPARATOR);
  const userPromptName = parts.length > 1 ? (parts[parts.length - 1] as UserMessagePromptName) : null;

  return userPromptName && userPromptName in UserMessagesAliasesMap ? userPromptName : null;
}
