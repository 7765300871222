import { Injectable, Provider } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import { UserEnvironmentService } from '@examdojo/core/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge, startWith, tap } from 'rxjs';
import { DateTimeService } from './date-time.service';

export const EXAMDOJO_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  } satisfies Record<string, Intl.DateTimeFormatOptions>,
  display: {
    dateInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'short', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  } satisfies Record<string, Intl.DateTimeFormatOptions>,
};

export const EXAMDOJO_DATE_TIME_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  } satisfies Record<string, Intl.DateTimeFormatOptions>,
  display: {
    dateInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  } satisfies Record<string, Intl.DateTimeFormatOptions>,
};

export function provideExamdojoDateAdapter(): Provider[] {
  return [
    {
      provide: DateAdapter,
      useClass: ExamdojoDateAdapter,
    },
    { provide: MAT_DATE_FORMATS, useValue: EXAMDOJO_DATE_FORMATS },
  ];
}

export function provideExamdojoDateTimeAdapter(): Provider[] {
  return [
    {
      provide: DateAdapter,
      useClass: ExamdojoDateAdapter,
    },
    { provide: MAT_DATE_FORMATS, useValue: EXAMDOJO_DATE_TIME_FORMATS },
  ];
}

@UntilDestroy()
@Injectable()
export class ExamdojoDateAdapter extends NativeDateAdapter {
  constructor(
    private readonly dateTimeService: DateTimeService,
    private readonly userEnvironmentService: UserEnvironmentService,
  ) {
    super();

    merge(this.userEnvironmentService.locale$, this.userEnvironmentService.timezone$)
      .pipe(
        startWith<string | null>(null),
        tap(() => this.setLocale(this.dateTimeService.locale)),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
