<ion-header [translucent]="true" class="shadow-none">
  <ion-toolbar class="!px-4">
    <ion-title class="common-ion-title">
      {{ 'welcome_to_examdojo' | transloco }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<div class="inner-content h-full">
  <div class="h-full p-4">
    <dojo-swiper-stepper [submitFn]="submitFn" class="onboarding-swiper h-full">
      <swiper-slide>
        <dojo-image
          class="mb-8"
          [classes]="'h-[210px] w-[172px]'"
          [src]="'assets/images/app-onboarding/app-onboarding-1.svg'"
          [alt]="('welcome' | transloco) + ', ' + state.user?.fullName"
        />

        <div class="text-display-md font-medium-exam mb-3 text-stone-800">
          {{ 'welcome' | transloco }}, {{ state.user?.fullName }}
        </div>
        <div class="font-normal-exam text-md text-stone-600">{{ 'app_onboarding.step_1.description' | transloco }}</div>
      </swiper-slide>

      <swiper-slide>
        <div
          class="small:p-7 mb-8 flex h-[40%] max-h-[17rem] w-[17rem] items-center justify-center rounded-[0.75rem] bg-stone-100"
        >
          <img
            src="assets/images/app-onboarding/app-onboarding-2.png"
            class="max-h-[80%] max-w-[80%] rounded-[0.75rem] object-contain"
            [alt]="'app_onboarding.step_2.header' | transloco"
          />
        </div>

        <div class="text-display-md font-medium-exam mb-3 text-stone-800">
          {{ 'app_onboarding.step_2.header' | transloco }}
        </div>
        <div class="font-normal-exam text-md text-stone-600">{{ 'app_onboarding.step_2.description' | transloco }}</div>
      </swiper-slide>

      <swiper-slide>
        <div class="text-display-md font-medium-exam mb-3 text-stone-800">
          {{ 'app_onboarding.step_3.header' | transloco }}
        </div>
        <div class="font-normal-exam text-md text-stone-600">{{ 'app_onboarding.step_3.description' | transloco }}</div>

        <div class="mt-8 flex h-[40%] max-h-[17rem] w-[17rem] items-end justify-center rounded-[0.75rem] bg-stone-100">
          <img
            src="assets/images/app-onboarding/app-onboarding-3.png"
            class="max-h-[100%] max-w-[100%] rounded-[0.75rem] object-cover"
            [alt]="'app_onboarding.step_3.header' | transloco"
          />
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="text-display-md font-medium-exam mb-3 text-stone-800">
          {{ 'app_onboarding.step_4.header' | transloco }}
        </div>
        <div class="font-normal-exam text-md text-stone-600">{{ 'app_onboarding.step_4.description' | transloco }}</div>

        <div class="mt-8 flex h-[40%] max-h-[17rem] w-[17rem] rounded-[0.75rem] bg-stone-100">
          <img
            src="assets/images/app-onboarding/app-onboarding-4.png"
            class="max-h-full w-full rounded-[0.75rem] object-cover object-bottom"
            [alt]="'app_onboarding.step_4.header' | transloco"
          />
        </div>
      </swiper-slide>

      <div class="controls">
        <dojo-button swiperStepperPrevious [fill]="'outline'" [small]="true">
          <y42-icon [icon]="'chevron-left'" class="h-6 items-center" />
        </dojo-button>

        <dojo-button swiperStepperNext class="small:grow-0 grow">{{ 'continue' | transloco }}</dojo-button>
      </div>
    </dojo-swiper-stepper>
  </div>
</div>
