<mat-sidenav-container class="shell-container h-full">
  <mat-sidenav
    mode="side"
    [opened]="state.showSideNav"
    [ngClass]="isCompact() ? 'w-[78px]' : 'w-[256px]'"
    [class.is-compact]="isCompact()"
  >
    <dojo-sidenav [isCompact]="isCompact()" />
  </mat-sidenav>
  <mat-sidenav-content class="h-full !overflow-y-hidden bg-white">
    <router-outlet />
  </mat-sidenav-content>
</mat-sidenav-container>
