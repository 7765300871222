import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { mapToVoid } from '@examdojo/rxjs';
import { first } from 'rxjs';
import { CategoryService } from './category.service';

export const categoriesResolver: ResolveFn<void> = () => {
  const categoryService = inject(CategoryService);
  return categoryService.fetchAll().pipe(first(), mapToVoid());
};
