import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { InternationalizationService } from '@examdojo/core/i18n';
import { mapToVoid, rethrowError, shareOneReplay } from '@examdojo/core/rxjs';
// import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastService } from '@examdojo/core/toast';
import {
  USER_ROLE_ORDER,
  USER_UPDATE_ALLOWED_KEYS,
  UserHttpModel,
  UserRole,
  UserStoreModel,
  UserUpdateModel,
} from '@examdojo/models/user';
import { RealtimeChangesListenEvent, RealtimeService, TableUpdateModel } from '@examdojo/supabase';
import { sanitizeObject } from '@examdojo/util/sanitize-object';
import { RealtimePostgresDeletePayload } from '@supabase/supabase-js';
import { concatMap, distinctUntilChanged, EMPTY, map, of, switchMap, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { UserHttpService } from './user-http.service';
import { UserQuery } from './user.query';
import { UserStore } from './user.store';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private readonly userHttpService: UserHttpService,
    private readonly userStore: UserStore,
    private readonly userQuery: UserQuery,
    private readonly authService: AuthService,
    private readonly realtimeService: RealtimeService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly toastService: ToastService,
    private readonly internationalizationService: InternationalizationService,
  ) {
    // TODO: enable when BE will implement removing of public.users
    // this.listenForDeletedProfileEvent().pipe(takeUntilDestroyed()).subscribe();
  }

  readonly currentUserRole$ = this.authService.currentUser$.pipe(
    switchMap((currentUser) => {
      if (!currentUser) {
        return of(undefined);
      }

      return this.userHttpService.fetchCurrentUserRole();
    }),
    this.errorHandlerService.catchError('[UserService]: Failed to fetch current user role', rethrowError(), {
      toast: 'Failed to fetch current user role',
    }),
    shareOneReplay(),
  );

  doesCurrentUserHaveRoleOrHigher(role: UserRole) {
    const roleIndex = USER_ROLE_ORDER.findIndex((r) => r === role);

    return this.currentUserRole$.pipe(
      map((currentUserRole) => {
        if (currentUserRole === null) {
          return false;
        }
        const currentUserRoleIndex = USER_ROLE_ORDER.findIndex((r) => r === currentUserRole);
        return currentUserRoleIndex >= roleIndex;
      }),
      distinctUntilChanged(),
    );
  }

  fetchActiveUserProfile() {
    return this.userHttpService.fetchActiveUserProfile().pipe(
      map((data) => this.mapUserHttpModelToStoreModel(data)),
      tap((user) => {
        this.userStore.upsertMany([user]);
        this.userStore.setActive(user.id);
      }),
      this.errorHandlerService.catchError('[UserService]: Failed to fetch current user profile', rethrowError(), {
        toast: 'Failed to fetch current user profile',
      }),
    );
  }

  fetchAll() {
    return this.userHttpService.fetchAll().pipe(
      tap((users) => this.userStore.upsertMany(users.map((user) => this.mapUserHttpModelToStoreModel(user)))),
      this.errorHandlerService.catchError('[UserService]: Failed to fetch users', rethrowError(), {
        toast: 'Failed to fetch users',
      }),
    );
  }

  update(id: UserStoreModel['id'], updateModel: UserUpdateModel) {
    const user = this.mapUpdateModelToHttpUpdateModel(updateModel);

    return this.userHttpService.update(id, user).pipe(
      tap((data) => {
        this.userStore.update(data.id, this.mapUserHttpModelToStoreModel(data));
      }),
      mapToVoid(),
      this.errorHandlerService.catchError('[UserService]: Updating user failed', rethrowError()),
    );
  }

  invite(invitationData: { first_name: string; last_name: string; email: string }) {
    return this.userHttpService.invite(invitationData);
  }

  deleteProfile() {
    return this.userHttpService.deleteProfile().pipe(
      tap(() => {
        if (this.userQuery.getActiveId()) {
          this.handleDeleteProfile();
        }
      }),
      this.errorHandlerService.catchError('Failed to delete user', () => EMPTY, {
        toast: 'Failed to delete user',
      }),
    );
  }

  private listenForDeletedProfileEvent() {
    return this.userQuery.selectActiveId().pipe(
      distinctUntilChanged(),
      switchMap((userId) => {
        if (!userId) {
          return of([]);
        }

        return this.realtimeService
          .listenRealtime<UserHttpModel>({
            schema: 'public',
            table: 'users',
            event: RealtimeChangesListenEvent.DELETE,
          })
          .pipe(
            map((payload) => payload as RealtimePostgresDeletePayload<UserHttpModel>),
            concatMap((payload) => {
              if (
                payload.eventType === RealtimeChangesListenEvent.DELETE &&
                payload.old?.id &&
                this.userQuery.getActiveId() === payload.old.id
              ) {
                this.handleDeleteProfile();
              }

              return of();
            }),
          );
      }),
    );
  }

  private handleDeleteProfile() {
    this.userStore.reset();
    this.authService.handleLogout();
    this.toastService.error(this.internationalizationService.translate('user_removed'));
  }

  private mapUserHttpModelToStoreModel(questionHttpModel: UserHttpModel): UserStoreModel {
    return { ...questionHttpModel, origin: questionHttpModel.origin as unknown as UserStoreModel['origin'] };
  }

  private mapUpdateModelToHttpUpdateModel(updateModel: UserUpdateModel): TableUpdateModel<'users'> {
    return sanitizeObject(updateModel, USER_UPDATE_ALLOWED_KEYS);
  }
}
