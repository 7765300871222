import { Injectable } from '@angular/core';
import { ExamdojoSupabaseService } from '@examdojo/supabase';
import { from, Observable } from 'rxjs';
import { CategoryHttpModel, CategoryStoreModel } from './category.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryHttpService {
  constructor(private readonly supabase: ExamdojoSupabaseService) {}

  fetchAll(): Observable<CategoryHttpModel[]> {
    return from(
      this.supabase.client
        .from('categories')
        .select()
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  fetch(id: CategoryStoreModel['id']): Observable<CategoryHttpModel | null> {
    return from(
      this.supabase.client
        .from('categories')
        .select()
        .eq('id', id)
        .maybeSingle()
        .throwOnError()
        .then((response) => response.data),
    );
  }
}
