import { TopicCategory } from '@examdojo/category';
import {
  QuestionImageStoreModel,
  QuestionStoreModel,
  QuestionVoteStoreModel,
  StemStoreModel,
  StimulusStoreModel,
} from '@examdojo/models/question';
import { QuestionEngagementGradingStoreModel } from '@examdojo/models/question/question-engagement-grading.model';
import { QuestionEngagementStatusStoreModel } from '@examdojo/models/question/question-engagement-status.model';
import { QuestionEngagementStoreModel } from '@examdojo/models/question/question-engagement.model';
import { UserXpStoreModel } from '@examdojo/models/question/user-xp.model';

export type QuestionItemQuestionImageStoreModel = QuestionImageStoreModel & {
  questionImageUrl: string | null;
};

export interface QuestionState {
  question?: QuestionStoreModel;
  items?: Array<StimulusStoreModel | StemStoreModel | QuestionItemQuestionImageStoreModel>;
  engagement?: QuestionEngagementStoreModel;
  engagementStatus?: QuestionEngagementStatusStoreModel;
  gradings?: QuestionEngagementGradingStoreModel[];
  topic?: TopicCategory;
  userXp?: UserXpStoreModel;
  userVote?: QuestionVoteStoreModel;
  grading?: {
    gradingId: number;
    userGradingVote?: boolean;
  };
  openCaptureDialog?: boolean;
}

export type QuestionContext = Required<Pick<QuestionState, 'question' | 'items' | 'engagement'>>;

export enum GradeStatus {
  Positive = 'positive',
  Warning = 'warning',
  Fail = 'fail',
}
