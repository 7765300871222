import { ensureArray } from './ensure-array';
import { normalizeString } from './normalize-string';

/**
 * Looks up if a term in found in a given string
 *
 * Ignores case and accents.
 * Also accepts array of terms and sources.
 * @param searchFor
 * @param inside
 */
export function matchStringIn(searchFor: string | string[], inside: string | string[]): boolean {
  const terms = ensureArray(searchFor).map(normalizeString);
  const sources = ensureArray(inside).map(normalizeString);

  return sources.some((source) => terms.some((term) => source.includes(term)));
}
