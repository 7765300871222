import { Directive, Input, Optional } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { AutocompleteSelectComponent } from '@examdojo/ui/autocomplete-select';
import { FlatSingleSelectComponent } from '@examdojo/ui/flat-select';
import { NumberInputComponent, TextareaInputComponent, TextInputComponent } from '@examdojo/ui/input';
import { debounceTime, filter, tap } from 'rxjs';
import { SwiperStepperComponent } from '../swiper-stepper';

@Directive({
  selector:
    'dojo-text-input[focusWhenSlideActive], dojo-number-input[focusWhenSlideActive], dojo-textarea-input[focusWhenSlideActive] dojo-flat-select[focusWhenSlideActive], dojo-autocomplete-select[focusWhenSlideActive]',
  standalone: true,
})
export class FocusWhenSlideActiveDirective {
  constructor(
    private readonly swiperStepperComponent: SwiperStepperComponent<FormGroup>,
    @Optional() private readonly textInputComponent?: TextInputComponent,
    @Optional() private readonly numberInputComponent?: NumberInputComponent,
    @Optional() private readonly textareaInputComponent?: TextareaInputComponent,
    @Optional() private readonly flatSingleSelectComponent?: FlatSingleSelectComponent,
    @Optional() private readonly autocompleteSelectComponent?: AutocompleteSelectComponent,
  ) {
    this.swiperStepperComponent.activeSlide$
      .pipe(
        filter((activeSlide) => !!activeSlide && activeSlide.controlName === this.controlName),
        debounceTime(0),
        tap(() => {
          const inputComponent = this.textInputComponent || this.numberInputComponent || this.textareaInputComponent;

          if (inputComponent) {
            inputComponent!.focus();
            return;
          }

          if (this.flatSingleSelectComponent) {
            this.flatSingleSelectComponent!.setFocus();
            return;
          }

          if (this.autocompleteSelectComponent) {
            this.autocompleteSelectComponent!.setFocus();
            return;
          }

          console.warn(`No focusable component found for controlName: ${this.controlName}`);
        }),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  @Input({ alias: 'focusWhenSlideActive', required: true }) controlName!: string;
}
