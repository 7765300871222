import { Injectable } from '@angular/core';
import { AbstractDialogService } from '@examdojo/ui/dialog';
import { assertNonNullable } from '@examdojo/util/assert';
import { firstValueFrom } from 'rxjs';
import { UserQuery } from '../user/user.query';
import { UserService } from '../user/user.service';
import { AppOnboardingDialogComponent } from './app-onboarding-dialog.component';

export const APP_ONBOARDING_DIALOG_ID = 'app-onboarding';

@Injectable()
export class AppOnboardingDialogService extends AbstractDialogService {
  constructor(
    private readonly userQuery: UserQuery,
    private readonly userService: UserService,
  ) {
    super();
  }

  readonly id = APP_ONBOARDING_DIALOG_ID;
  protected override readonly component = AppOnboardingDialogComponent;

  override options = {
    cssClass: 'app-onboarding-dialog action-sheet-on-mobile',
    canDismiss: true,
    backdropDismiss: false,
    showBackdrop: true,
    handle: false,
  };

  override async openDialog() {
    const result = await super.openDialog({ isSingleBreakpoint: true });

    const userId = this.userQuery.getActiveId();
    assertNonNullable(userId, 'userId');

    await firstValueFrom(this.userService.update(userId, { onboarded_app: true }));

    return result;
  }
}
