import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatSelectTrigger } from '@angular/material/select';
import { _SelectComponent, SelectOption } from '@examdojo/core/select';
import { assertNonNullable } from '@examdojo/util/assert';
import { isNotNullish } from '@examdojo/util/nullish';
import { ActionSheetController } from '@ionic/angular/standalone';
import { ActionSheetButton } from '@ionic/core/dist/types/components/action-sheet/action-sheet-interface';
import { ButtonComponent, ButtonExpand, ButtonFill } from '../button';

@Component({
  selector: 'dojo-action-sheet-select',
  standalone: true,
  imports: [ButtonComponent, MatSelectTrigger, NgTemplateOutlet],
  templateUrl: './action-sheet-select.component.html',
  styleUrl: './action-sheet-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionSheetSelectComponent<
  Option extends SelectOption,
  Value = NonNullable<SelectOption['value']>,
> extends _SelectComponent<Option, Value> {
  constructor(private readonly actionSheetCtrl: ActionSheetController) {
    super();
  }

  readonly _multiple = false;

  readonly expand = input<ButtonExpand>();
  readonly small = input<boolean>(false);
  readonly danger = input<boolean>(false);
  readonly fill = input<ButtonFill>('solid');

  async presentActionSheet(): Promise<Value | undefined> {
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.placeholder,
      buttons: this.getActionSheetButtons(),
    });

    await actionSheet.present();

    const result = await actionSheet.onWillDismiss<Value>();
    const selectedValue = result.data;

    if (isNotNullish(selectedValue)) {
      this.formCtrl.setValue(selectedValue);
      this.formCtrl.markAsDirty();
    }

    return result.data;
  }

  private getActionSheetButtons(): ActionSheetButton[] {
    assertNonNullable(this.options, 'options');

    return this.options.map((option) => {
      const selected = this.formCtrl.value === option.value;
      return {
        text: option.label,
        role: selected ? 'selected' : undefined,
        data: option.value,
      } satisfies ActionSheetButton;
    });
  }
}
