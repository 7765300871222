import { Provider } from '@angular/core';
import { MARKED_OPTIONS, MarkedOptions, MarkedRenderer } from 'ngx-markdown';

const ChatMarkedRendererHtmlTokenMap: Record<string, string> = {
  '<tip>': '<div class="example-block tip"><div class="example-block-text">',
  '</tip>': '</div></div>',

  '<note>': '<div class="example-block note"><div class="example-block-text">',
  '</note>': '</div></div>',

  '<warning>': '<div class="example-block warning"><div class="example-block-text">',
  '</warning>': '</div></div>',

  '<example_given>': '<div class="example-block example"><div class="example-block-text">',
  '</example_given>': '</div></div>',
};

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.html = (html: string, block?: boolean) => {
    const tagPattern = /<\/?(tip|note|warning|example_given)>/g;

    let hasCustomTags = false;

    const modifiedHtml = html.replace(tagPattern, (match) => {
      if (ChatMarkedRendererHtmlTokenMap[match]) {
        hasCustomTags = true;
        return ChatMarkedRendererHtmlTokenMap[match];
      }

      return match;
    });

    return hasCustomTags ? modifiedHtml : MarkedRenderer.prototype.html.call(renderer, html, block);
  };

  return { renderer };
}

export const MarkedOptionsProvider: Provider = {
  provide: MARKED_OPTIONS,
  useFactory: markedOptionsFactory,
};
