import { Route } from '@angular/router';
import { redirectToGuard } from '@examdojo/auth';
import { categoriesResolver } from '@examdojo/category';
import { NotFoundComponent } from '@examdojo/core/not-found';
import { AppOnboardingDialogService } from './app-onboarding/app-onboarding-dialog.service';
import { appOnboardingGuard } from './app-onboarding/app-onboarding.guard';
import { RootUrlParts } from './app.model';
import { AuthGuard } from './auth/auth.guard';
import { authRoutes } from './auth/auth.routes';
import { userOnboardingGuard } from './user-onboarding/user-onboarding.guard';
import { userOnboardingRoutes } from './user-onboarding/user-onboarding.routes';
import { hasRoleGuard, isOnWaitlistGuard } from './waitlist/waitlist.guard';

export const appRoutes: Route[] = [
  ...authRoutes,
  ...userOnboardingRoutes,
  {
    path: 'waitlist',
    canActivate: [AuthGuard, isOnWaitlistGuard],
    loadComponent: () => import('./waitlist/waitlist.component').then((m) => m.WaitlistComponent),
  },
  {
    path: '',
    canActivate: [AuthGuard, hasRoleGuard, redirectToGuard, userOnboardingGuard, appOnboardingGuard],
    providers: [AppOnboardingDialogService],
    resolve: {
      categories: categoriesResolver,
    },
    children: [
      {
        path: '',
        redirectTo: RootUrlParts.TopicPractice,
        pathMatch: 'full',
      },
      {
        path: RootUrlParts.TopicPractice,
        loadChildren: () =>
          import('./features/topic-practice/topic-practice.routes').then((m) => m.TOPIC_PRACTICE_ROUTES),
      },
      {
        path: RootUrlParts.Profile,
        loadChildren: () => import('./profile/profile.routes').then((m) => m.PROFILE_ROUTES),
      },
      {
        path: RootUrlParts.LearnHub,
        loadComponent: () => import('./features/learn-hub/learn-hub.component').then((m) => m.LearnHubComponent),
      },
      {
        path: RootUrlParts.Assessments,
        loadComponent: () => import('./features/assessments/assessments.component').then((m) => m.AssessmentsComponent),
      },
      {
        path: RootUrlParts.Leaderboards,
        loadComponent: () =>
          import('./features/leaderboards/leaderboards.component').then((m) => m.LeaderboardsComponent),
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
