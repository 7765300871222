<div class="h-full bg-stone-50 p-3" [class.is-compact]="isCompact">
  <div class="flex h-full flex-col justify-between">
    <div class="grow">
      <a
        [routerLink]="'/'"
        class="logo-link mb-4 flex h-[54px] rounded-[0.75rem] px-3 transition-colors hover:bg-neutral-100"
      >
        @if (isCompact) {
          <img src="assets/images/logo/logo-compact.svg" alt="Exam dojo" class="w-[26px]" />
        } @else {
          <img src="assets/images/logo/logo-color-horizontal.svg" alt="Exam dojo" class="w-[147px]" />
        }
      </a>

      <div class="flex flex-col gap-2">
        @for (page of featurePages; track page.route) {
          <a
            class="sidebar-link text-secondary text-md flex items-center gap-4 p-2 font-medium"
            [routerLink]="page.route"
            routerLinkActive="active"
          >
            <img [src]="page.icon" [alt]="page.label" class="h-[32px]" />
            <span class="sidebar-link-text">{{ page.label | transloco }}</span>
          </a>
        }
      </div>
    </div>

    @if (state.user) {
      <a
        class="sidebar-link text-secondary flex items-center gap-4 rounded-[0.75rem] px-2 py-0.5 font-medium transition-colors hover:bg-neutral-100"
        [routerLink]="RootUrlParts.Profile"
      >
        <y42-icon [icon]="'assets/images/user-round.svg'" [size]="32"></y42-icon>
        <span class="sidebar-link-text text-md">{{ state.user.fullName }}</span>
        <dojo-icon-button
          [icon]="'ellipsis'"
          [transparent]="true"
          [iconSize]="20"
          class="ml-auto"
          [class.hidden]="isCompact"
        />
      </a>
    }
  </div>
</div>
