import { ignoreElements, mergeMap, Observable, of } from 'rxjs';

/**
 * merges the source observable with the provided observable, but only runs the provided observable once.
 * It returns an observable that does not emit the values from the provided observable, only the source observable.
 */
export function mergeMapOnce<T>(obs: () => Observable<unknown>) {
  let hasRun = false;

  return (source$: Observable<T>) =>
    source$.pipe(
      mergeMap((value) => {
        if (hasRun) {
          return of(value);
        }

        hasRun = true;

        return obs().pipe(
          ignoreElements(),
          mergeMap(() => of(value)),
        );
      }),
    );
}
