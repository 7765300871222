import { Injectable } from '@angular/core';
import { Store } from '@examdojo/state';
import { createStore, withProps } from '@ngneat/elf';
import { QuestionState } from './question.model';

function createInitialState(): QuestionState {
  return {};
}

@Injectable({ providedIn: 'root' })
export class QuestionStore extends Store<QuestionState> {
  constructor() {
    super(createStore({ name: 'question' }, withProps<QuestionState>(createInitialState())));
  }
}
