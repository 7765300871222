import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CategoryQuery, CourseCategory } from '@examdojo/category';
import { PosthogErrorHandlingService } from '@examdojo/core/error-handling/posthog/posthog-error-handling.service';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { combineLatest, filter, first, map, merge, switchMap, take, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { UserQuery } from '../user/user.query';

@Injectable()
export class PosthogUserTraitsService {
  constructor(
    private readonly authService: AuthService,
    private readonly userQuery: UserQuery,
    private readonly posthogErrorHandlingService: PosthogErrorHandlingService,
    private readonly categoryQuery: CategoryQuery,
    private readonly errorHandler: ErrorHandlerService,
  ) {
    merge(this.setIdentityOnLogin(), this.setCourse(), this.resetOnLogout()).pipe(takeUntilDestroyed()).subscribe();
  }

  private readonly onboardedUser$ = this.authService.currentUser$.pipe(
    filter(Boolean),
    take(1),
    switchMap(() =>
      this.userQuery.isUserOnboarded$.pipe(
        first(Boolean),
        switchMap(() => this.userQuery.active$),
        first(Boolean),
      ),
    ),
  );

  private setIdentityOnLogin() {
    return this.onboardedUser$.pipe(
      tap((user) => {
        this.posthogErrorHandlingService.identifyUser(user.id, {
          name: user.fullName,
          email: user.email,
        });
      }),
    );
  }

  private setCourse() {
    return combineLatest([this.onboardedUser$, this.categoryQuery.entities$]).pipe(
      map(([user, categories]) => categories.find((category) => category.id === user.course_id)),
      filter((category): category is CourseCategory => !!category && category.category_level === 'course'),
      take(1),
      tap(({ course }) => {
        const [_, syllabus, courseLevel] = course.match(/^(.*?)(..)$/) ?? [];

        if (!(syllabus && courseLevel)) {
          this.errorHandler.error('Could not parse course', { context: { course } });
          return;
        }

        this.posthogErrorHandlingService.setUserTraits({
          syllabus,
          course_level: courseLevel,
        });
      }),
    );
  }

  private resetOnLogout() {
    return this.authService.hasLoggedOut$.pipe(
      tap(() => {
        this.posthogErrorHandlingService.resetUser();
      }),
    );
  }
}
