<div class="flex flex-col items-center justify-center" [ngClass]="classes()">
  <ion-spinner
    name="crescent"
    class="mx-auto h-4 w-4"
    color="dark"
    [style.display]="isImageLoaded() ? 'none' : 'block'"
  ></ion-spinner>

  <ion-img
    [src]="src()"
    alt="{{ alt() }}"
    (ionImgDidLoad)="onImageLoad()"
    [style.opacity]="isImageLoaded() ? 1 : 0"
  ></ion-img>
</div>
