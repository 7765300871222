import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { first, map } from 'rxjs';
import { DEFAULT_URL } from '../app.model';
import { UserService } from '../user/user.service';

export const hasRoleGuard: CanActivateFn = () => {
  const userService = inject(UserService);
  const router = inject(Router);

  return userService.currentUserRole$.pipe(
    first((role) => role !== undefined),
    map((role) => {
      if (role) {
        return true;
      }

      return router.createUrlTree(['/waitlist']);
    }),
  );
};

export const isOnWaitlistGuard: CanActivateFn = () => {
  const userService = inject(UserService);
  const router = inject(Router);

  return userService.currentUserRole$.pipe(
    first((role) => role !== undefined),
    map((role) => {
      if (role) {
        return router.createUrlTree([DEFAULT_URL]);
      }

      return true;
    }),
  );
};
