import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { connectState, InputObservable } from '@examdojo/angular/util';
import { map, Observable, of, startWith, switchMap, timer } from 'rxjs';
import { Datestring } from '../date-time.model';
import { DateTimeService } from '../date-time.service';

const ONE_SECOND = 1_000;

export interface DurationRange {
  startedAt: Datestring | null | undefined;
  finishedAt: Datestring | null | undefined;
}

@UntilDestroy()
@Component({
  selector: 'y42-duration[durationRange]',
  template: `{{ state.duration }}`,
  host: { class: 'truncate' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationComponent {
  constructor(private readonly dateTimeService: DateTimeService) {}

  @Input()
  @InputObservable()
  durationRange?: DurationRange;
  private readonly durationRange$!: Observable<DurationRange | undefined>;

  readonly state = connectState({
    duration: this.durationRange$.pipe(
      switchMap((val) => {
        const startedAt = val?.startedAt;
        const finishedAt = val?.finishedAt;

        if (!startedAt) {
          return of('-');
        }

        if (startedAt && finishedAt) {
          return of(this.calculateDuration(startedAt, finishedAt));
        }

        return timer(0, ONE_SECOND).pipe(
          map(() => {
            return this.calculateDurationFromNow(startedAt);
          }),
          startWith(this.calculateDurationFromNow(startedAt)),
        );
      }),
    ),
  });

  private calculateDuration(startedAt: Datestring, finishedAt: Datestring) {
    const diff = this.dateTimeService.diff(startedAt, finishedAt, 'seconds');
    return diff === 0 && finishedAt
      ? `< ${this.dateTimeService.formatDuration(1, { unit: 'seconds' })}`
      : this.dateTimeService.formatDuration(diff, { unit: 'seconds' });
  }

  private calculateDurationFromNow(startedAt: Datestring) {
    return this.calculateDuration(startedAt, new Date());
  }
}
