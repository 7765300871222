import { Features } from './features/features.model';

enum RootRoutes {
  Onboarding = 'onboarding',
  Profile = 'profile',
}

export const RootUrlParts = {
  ...RootRoutes,
  ...Features,
};

export const DEFAULT_ROOT_URL_PART = '';
export const DEFAULT_URL = `/${DEFAULT_ROOT_URL_PART}`;
