import { ChangeDetectionStrategy, Component, computed, contentChildren, input, Input, viewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormConfirmFn } from '@examdojo/core/form-submit-button';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { assertNonNullable } from '@examdojo/util/assert';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  startWith,
  Subject,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { SwiperOptions } from 'swiper/types';
import { SwiperComponent, SwiperSlideDirective } from '../swiper';

export { FormConfirmFn as StepperSubmitFn } from '@examdojo/core/form-submit-button';

@Component({
  selector: 'dojo-swiper-stepper',
  standalone: true,
  imports: [SwiperComponent, ReactiveFormsModule],
  templateUrl: './swiper-stepper.component.html',
  styleUrl: './swiper-stepper.component.scss',
  host: { class: 'block' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwiperStepperComponent<TFormGroup extends FormGroup, TReturn = unknown> {
  constructor(private readonly errorHandlerService: ErrorHandlerService) {
    this.disableSwipeNextOnActiveControlStatusChange().pipe(takeUntilDestroyed()).subscribe();
  }

  @Input() swiperOptions?: SwiperOptions = {
    slidesPerView: 1,
    pagination: {
      type: 'bullets',
      clickable: true,
    },
  };

  @Input() goNextDelayMs?: number;

  @Input() paginationTop?: boolean;

  readonly form = input<TFormGroup>(new FormGroup({}) as TFormGroup);
  readonly submitFn = input.required<FormConfirmFn<TFormGroup, TReturn>>();

  readonly swiperComponent = viewChild(SwiperComponent);
  readonly swiperInstance = computed(() => this.swiperComponent()?.instance());

  private readonly swiperComponent$ = toObservable(this.swiperComponent).pipe(filter(Boolean));
  private readonly swiperInstance$ = toObservable(this.swiperInstance).pipe(filter(Boolean));

  readonly slides = contentChildren(SwiperSlideDirective);

  readonly activeSlideIndex$ = this.swiperComponent$.pipe(
    switchMap((swiperComponent) => swiperComponent.activeSlideIndex$),
  );

  readonly activeSlide$ = combineLatest([toObservable(this.slides), this.activeSlideIndex$]).pipe(
    map(([slides, activeIndex]) => {
      const activeSlide = slides[activeIndex];
      if (!activeSlide) {
        this.errorHandlerService.error(
          `[SwiperStepperComponent.activeSlide$]: No slide found at index ${activeIndex}`,
          {
            context: { slides, activeIndex, activeSlide },
          },
        );
        return undefined;
      }
      return activeSlide;
    }),
  );

  readonly activeControl$ = combineLatest([toObservable(this.form), this.activeSlide$.pipe(filter(Boolean))]).pipe(
    map(([form, activeSlide]) => {
      const controlName = activeSlide.controlName;
      if (!controlName) {
        return undefined;
      }

      const ctl = form.get(controlName);
      if (!ctl) {
        this.errorHandlerService.error(
          `[SwiperStepperComponent.activeControl$]: No control found with name ${controlName}`,
          {
            context: { controlName, form, activeSlide },
          },
        );
        return undefined;
      }
      return ctl;
    }),
  );

  readonly activeControlStatus$ = this.activeControl$.pipe(
    filter(Boolean),
    switchMap((control) => {
      if (!control) {
        return of(undefined);
      }
      return control.statusChanges.pipe(startWith(control.status));
    }),
    distinctUntilChanged(),
  );

  readonly isLastStep$ = this.activeSlideIndex$.pipe(
    withLatestFrom(this.swiperInstance$),
    map(([, swiperInstance]) => swiperInstance.isEnd),
    distinctUntilChanged(),
  );

  private readonly goNextTrigger$$ = new Subject<void>();
  readonly goNextTrigger$ = this.goNextTrigger$$.asObservable();

  goNextOrSubmit() {
    this.goNextTrigger$$.next();
  }

  private disableSwipeNextOnActiveControlStatusChange() {
    return this.activeControlStatus$.pipe(
      filter(Boolean),
      tap((status) => {
        const swiperComponent = this.swiperComponent();
        assertNonNullable(swiperComponent, 'swiperComponent');
        swiperComponent.updateOption('allowSlideNext', status === 'VALID');
      }),
    );
  }
}
