import { Injectable } from '@angular/core';
import { ExamdojoSupabaseService } from '@examdojo/supabase';
import { from } from 'rxjs';
import { CourseTree } from './category-topic-area.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryTopicAreaHttpService {
  constructor(private readonly supabase: ExamdojoSupabaseService) {}

  fetchUserCourseTree() {
    return from(
      this.supabase.client
        .rpc('get_course_structure')
        .throwOnError()
        .then((res) => res.data as CourseTree),
    );
  }
}
