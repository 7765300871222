import { Injectable } from '@angular/core';
import { EntityStore } from '@examdojo/state';
import { CategoryTopicAreaState, CategoryTopicAreaStoreModel } from './category-topic-area.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryTopicAreaStore extends EntityStore<CategoryTopicAreaState, CategoryTopicAreaStoreModel, 'id'> {
  constructor() {
    super({
      name: 'course-tree',
      idKey: 'id',
      initialPropsState: {},
    });
  }
}
