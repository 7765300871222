import { Injectable } from '@angular/core';
import { ensureArray } from '@examdojo/util/ensure-array';
import { OrArray } from '@ngneat/elf';
import { REALTIME_LISTEN_TYPES, REALTIME_POSTGRES_CHANGES_LISTEN_EVENT } from '@supabase/realtime-js';
import { RealtimePostgresChangesPayload } from '@supabase/supabase-js';
import { Observable, startWith, switchMap } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ExamdojoSupabaseService, SchemaName, TableName } from './index';

export { REALTIME_POSTGRES_CHANGES_LISTEN_EVENT as RealtimeChangesListenEvent } from '@supabase/realtime-js';

export interface RealtimeChangesTarget<
  R extends REALTIME_POSTGRES_CHANGES_LISTEN_EVENT,
  S extends SchemaName,
  T extends TableName<S> = TableName<S>,
> {
  event: R;
  schema: S;
  table: T;
  filter?: string;
}

@Injectable({
  providedIn: 'root',
})
export class RealtimeService {
  constructor(private readonly supabase: ExamdojoSupabaseService) {}

  /**
   * Watches for changes in a set of tables and events
   * @param targets One or multiple targets to listen to, including the event type and filters
   * @param channelName Channel name - generated if not provided
   */
  listenRealtime<
    U extends Record<string, unknown> = Record<string, unknown>,
    R extends REALTIME_POSTGRES_CHANGES_LISTEN_EVENT = REALTIME_POSTGRES_CHANGES_LISTEN_EVENT,
    S extends SchemaName = 'public',
    T extends TableName<S> = TableName<S>,
  >(targets: OrArray<RealtimeChangesTarget<R, S, T>>, channelName: string = uuid()) {
    return new Observable<RealtimePostgresChangesPayload<U>>((subscriber) => {
      let channel = this.supabase.client.channel(channelName);

      for (const target of ensureArray(targets)) {
        channel = channel.on<U>(
          REALTIME_LISTEN_TYPES.POSTGRES_CHANGES,
          {
            ...target,
            // Supabase messed up the type union with the overloads
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            event: target.event as any,
            table: target.table as string,
          },
          (payload) => {
            subscriber.next(payload);
          },
        );
      }

      channel.subscribe();

      return () => {
        this.supabase.client.removeChannel(channel);
      };
    });
  }

  /**
   * Re-triggers the source observable whenever a change is detected in the specified targets
   * @param targets One or multiple targets to listen to, including the event type and filters
   */
  reTriggerOnRealtimeChanges<
    O extends Observable<unknown>,
    U extends Record<string, unknown>,
    R extends REALTIME_POSTGRES_CHANGES_LISTEN_EVENT,
    S extends SchemaName,
    T extends TableName<S> = TableName<S>,
  >(targets: OrArray<RealtimeChangesTarget<R, S, T>>) {
    const realtimeChanges$ = this.listenRealtime<U, R, S, T>(targets);

    return (source: O) =>
      realtimeChanges$.pipe(
        startWith(null),
        switchMap(() => source),
      );
  }
}
