import { Injectable } from '@angular/core';
import { QueryEntity } from '@examdojo/state';
import { map } from 'rxjs';
import {
  CategoryTopicAreaState,
  CategoryTopicAreaStoreModel,
  CategoryTopicAreaUIModel,
} from './category-topic-area.model';
import { CategoryTopicAreaStore } from './category-topic-area.store';
import { CategoryQuery } from './category.query';
import { TopicCategory } from './category.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryTopicAreaQuery extends QueryEntity<
  CategoryTopicAreaState,
  CategoryTopicAreaStoreModel,
  CategoryTopicAreaUIModel,
  'id'
> {
  constructor(
    protected override store: CategoryTopicAreaStore,
    private readonly categoryQuery: CategoryQuery,
  ) {
    super(store);
  }

  readonly loading$ = this.selectLoading();

  readonly topicAreas$ = this.selectAllEntities({ filterEntity: (area) => area.topic_area !== 'recent' });
  readonly recentCategoryTopics$ = this.selectAllEntities({
    filterEntity: (area) => area.topic_area === 'recent',
  }).pipe(
    map((recentTopicArea) => recentTopicArea?.[0]?.topics ?? []),
    map((topics) => topics.map((topic) => this.categoryQuery.getEntity(topic.id) as TopicCategory)), // TODO: recent function does not return full topic data
  );
}
