import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { FeatureFlagService } from '@examdojo/core/feature-flag';
import { ExamDojoFeatureFlag, ExamdojoFeatureFlags } from '@examdojo/models/feature-flag';
import { NgClass } from '@angular/common';
import { SidenavComponent } from './sidenav/sidenav.component';

@Component({
  selector: 'dojo-shell',
  standalone: true,
  imports: [RouterOutlet, MatSidenavModule, SidenavComponent, NgClass],
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'h-full' },
})
export class ShellComponent {
  constructor(private readonly featureFlagService: FeatureFlagService<ExamdojoFeatureFlags>) {}

  readonly isCompact = input<boolean>(false);

  readonly state = connectState({
    showSideNav: this.featureFlagService.select(ExamDojoFeatureFlag.Sidenav),
  });
}
