<y42-single-select
  class="select-component-topic-details"
  [options]="options"
  [optionValue]="optionValue"
  [optionLabel]="optionLabel"
  [optionDisabled]="optionDisabled"
  [label]="label"
  [formCtrl]="formCtrl"
  [hideErrors]="hideErrors"
  [errorMessages]="errorMessages"
  [required]="required"
  [color]="color"
  [tooltipHint]="tooltipHint"
  [compact]="compact"
  [micro]="micro"
  [borderless]="borderless"
  [trackBy]="trackBy"
  [fullWidthField]="fullWidthField"
  [nativeSelect]="nativeSelect"
  [compareWith]="compareWith"
  [panelClass]="panelClass"
  [placeholder]="placeholder"
  [hideSingleSelectionIndicator]="hideSingleSelectionIndicator"
>
  <ng-content></ng-content>
</y42-single-select>
