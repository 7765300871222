<div class="custom-mat-form-wrapper">
  @if (label) {
    <div class="mat-form-label">
      @if (state.labelTemplate) {
        <ng-container *ngTemplateOutlet="state.labelTemplate" />
      } @else {
        <span>{{ label }}</span>
      }
    </div>
  }

  @if (autoCompleteErrorStateMatcher) {
    <mat-form-field appearance="fill" subscriptSizing="dynamic" class="w-full" [matTooltip]="state.tooltip">
      <input
        type="text"
        matInput
        [formControl]="searchControl"
        [matAutocomplete]="auto"
        [errorStateMatcher]="autoCompleteErrorStateMatcher"
        required
        [placeholder]="placeholder ?? ''"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="select($event)"
        (closed)="setSearchControlValue()"
        class="exam-autocomplete"
        hideSingleSelectionIndicator
      >
        @for (option of autocompleteState.filteredOptions; track option.value) {
          <mat-option [value]="option">
            @if (optionTemplate ?? selectOptionDirective?.templateRef; as tmpl) {
              <ng-container
                *ngTemplateOutlet="
                  tmpl;
                  context: {
                    $implicit: option.option
                  }
                "
              />
            } @else {
              {{ option.label }}
            }
          </mat-option>
        }

        <mat-option [hidden]="autocompleteState.filteredOptions.length" class="pointer-events-none">
          {{ 'examdojo.no_data' | transloco }}
        </mat-option>
      </mat-autocomplete>
      <ng-container matSuffix>
        <y42-icon [icon]="'chevron-down'" />
      </ng-container>

      @if (!hideErrors && state.errorMessage) {
        <mat-error>
          {{ state.errorMessage }}
        </mat-error>
      }
    </mat-form-field>
  }
</div>
