import timezones from 'timezones.json';

type Timezone = (typeof timezones)[number] & {
  value: string;
  longLabel: string;
  shortLabel: string;
};

const TIMEZONES: Timezone[] = timezones.map((timezone) => ({
  ...timezone,
  longLabel: timezone.text,
  shortLabel: timezone.value,
  value: timezone.utc[0] ?? '',
}));
export { TIMEZONES, Timezone };
