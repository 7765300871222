import { Observable, share, tap } from 'rxjs';

export function tapOnce<T>(fn: (v: T) => unknown) {
  return (source$: Observable<T>) => {
    let tapped = false;
    return source$.pipe(
      tap((value) => {
        if (!tapped) {
          fn(value);
          tapped = true;
        }
      }),
      share(),
    );
  };
}
