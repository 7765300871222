import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { mapToTrue } from '@examdojo/rxjs';
import { combineLatest, first, map, tap } from 'rxjs';
import { UserQuery } from '../user/user.query';
import { UserService } from '../user/user.service';
import { AppOnboardingDialogService } from './app-onboarding-dialog.service';

export const appOnboardingGuard: CanActivateFn = () => {
  const dialogService = inject(AppOnboardingDialogService);
  const userQuery = inject(UserQuery);
  const userService = inject(UserService);

  const isUserOnWaitlist$ = userService.currentUserRole$.pipe(
    first((role) => role !== undefined),
    map((role) => role === null),
  );

  const shouldDisplayOnboarding$ = combineLatest([userQuery.active$.pipe(first(Boolean)), isUserOnWaitlist$]).pipe(
    map(([user, isUserOnWaitlist]) => !isUserOnWaitlist && !user.onboarded_app),
  );

  return shouldDisplayOnboarding$.pipe(
    tap((shouldDisplayOnboarding) => {
      if (shouldDisplayOnboarding) {
        dialogService.openDialog();
      }
    }),
    mapToTrue(),
  );
};
