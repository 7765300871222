import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, OnInit, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { connectState } from '@examdojo/angular/util';
import { IconComponent } from '@examdojo/core/icon';
import { _SelectComponent, SelectOption } from '@examdojo/core/select';
import { assertNonNullable } from '@examdojo/core/util/assert';
import { matchStringIn } from '@examdojo/util/string-contains';
import { TranslocoPipe } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  tap,
} from 'rxjs';

export class AutoCompleteDirtyInvalidErrorStateMatcher<T> implements ErrorStateMatcher {
  constructor(private readonly parentControl: FormControl<T>) {}
  isErrorState(): boolean {
    return !!this.parentControl?.invalid && this.parentControl.dirty;
  }
}

@UntilDestroy()
@Component({
  selector: 'dojo-autocomplete-select',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatTooltipModule,
    IconComponent,
    TranslocoPipe,
  ],
  templateUrl: './autocomplete-select.component.html',
  styleUrls: ['./autocomplete-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteSelectComponent<Option extends object = SelectOption, Value = object>
  extends _SelectComponent<Option, Value>
  implements OnInit
{
  constructor() {
    super();

    this.setInitialFocus().pipe(takeUntilDestroyed()).subscribe();
  }

  readonly hasInitialFocus = input<boolean>(false);

  readonly matInput = viewChild<MatInput>(MatInput);

  readonly _multiple = false;

  readonly searchControl = new FormControl<string | Option>('', { nonNullable: true });

  autoCompleteErrorStateMatcher: ErrorStateMatcher | undefined = undefined;

  readonly lastValidValue$$ = new BehaviorSubject<SelectOption | null>(null);

  private readonly searchValue$ = this.searchControl.valueChanges.pipe(
    startWith(this.searchControl.value),
    distinctUntilChanged(),
  );

  readonly filteredOptions$ = combineLatest([
    combineLatest([this.options$, this.optionValue$, this.optionLabel$, this.optionDisabled$]).pipe(
      map(([options, optionValue, optionLabel, optionDisabled]) => {
        return this.mapOptions(options, optionValue, optionLabel, optionDisabled);
      }),
    ),
    this.searchValue$,
  ]).pipe(
    map(([options, searchTerm]) => {
      if (typeof searchTerm !== 'string' || !searchTerm) {
        return options;
      }

      const filtered = options?.filter(
        (option) =>
          option.label &&
          option.value &&
          typeof option.value === 'string' &&
          matchStringIn(searchTerm, [option.label, option.value]),
      );
      return filtered;
    }),
    map((options) => options.slice(0, 100) || []),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly autocompleteState = connectState({
    filteredOptions: this.filteredOptions$,
  });

  readonly displayFn = (option: SelectOption): string => option?.label || '';

  override ngOnInit() {
    assertNonNullable(this.formCtrl, 'this.formCtrl');
    this.trackFormCtrlValueChanges().pipe(untilDestroyed(this)).subscribe();
    this.autoCompleteErrorStateMatcher = new AutoCompleteDirtyInvalidErrorStateMatcher(this.formCtrl);
  }

  select(event: MatAutocompleteSelectedEvent) {
    const selected: SelectOption | null = event.option.value ?? null;
    if (selected) {
      this.lastValidValue$$.next(selected);
      this.formCtrl.setValue(selected.value as Value);
    }
  }

  setSearchControlValue() {
    const selectedOption = this.state.options?.find((option) => option.value === this.formCtrl.value);
    // Reset the search control when the typed in value does not match the available selection criteria.
    if (this.searchControl.value && !selectedOption) {
      if (this.lastValidValue$$.value) {
        this.searchControl.setValue(this.lastValidValue$$.value as Option);
        return;
      }
      this.searchControl.reset();
    }
  }

  setFocus() {
    this.matInput()?.focus();
  }

  private trackFormCtrlValueChanges() {
    return this.formCtrl.valueChanges.pipe(
      startWith(null),
      tap(() => {
        const selectedOption = this.state.options?.find((option) => option.value === this.formCtrl.value);
        this.searchControl.setValue(selectedOption ? (selectedOption as Option) : '');
      }),
    );
  }

  private setInitialFocus() {
    return this.ngAfterViewInit$.pipe(
      delay(0),
      filter(() => !!this.hasInitialFocus()),
      tap(() => this.setFocus()),
    );
  }
}
