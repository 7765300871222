import { Component, inject } from '@angular/core';
import { FeatureFlagService } from '@examdojo/core/feature-flag';
import { ExamdojoFeatureFlags } from '@examdojo/models/feature-flag';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { register as registerSwiper } from 'swiper/element/bundle';
import { APP_EFFECTS } from './effects';
import { IntercomService } from './intercom/intercom.service';
import { ShellComponent } from './shell/shell.component';

registerSwiper();

@Component({
  selector: 'dojo-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonApp, IonRouterOutlet, ShellComponent],
  providers: [...APP_EFFECTS],
})
export class AppComponent {
  private readonly instantiatedServices = [
    ...APP_EFFECTS,
    IntercomService,
    FeatureFlagService<ExamdojoFeatureFlags>,
  ].map((service) => inject(service));
}
