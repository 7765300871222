export type Datestring = Date | string;
export type UnitOfTime = keyof Duration;
export type ExtendedUnitOfTime = UnitOfTime | 'milliseconds';

export enum DateTimeFormat {
  /** Example for en-us: 02/01/20 */
  ShortDate = 'shortDate',
  /** Example for en-us: Feb 1, 2020 */
  MediumDate = 'mediumDate',
  /** Example for en-us: Saturday, February 01, 2020 */
  LongDate = 'longDate',
  /** Example for en-us: 10:00 AM */
  ShortTime = 'shortTime',
  /** Example for en-us: 10:00:00 AM */
  MediumTime = 'mediumTime',
  /** Example for en-us: 10:00:00 AM GMT+1 */
  LongTime = 'longTime',
  /** Example for en-us: 02/01/20, 10:00 AM */
  ShortDateTime = 'shortDateTime',
  /** Example for en-us: 02/01/20, 10:00:00 AM */
  ShortDateTimeWithSeconds = 'shortDateTimeWithSeconds',
  /** Example for en-us: 02/01/20, 10:00:00.000 AM */
  ShortDateTimeWithSecondsAndMs = 'ShortDateTimeWithSecondsAndMs',
  /** Example for en-us: Feb 1, 2020, 10:00 AM */
  MediumDateTime = 'mediumDateTime',
  /** Example for en-us: Saturday, February 01, 2020 at 10:00:00 AM */
  LongDateTime = 'longDateTime',
  /**
   * Example for en-us: 2023
   */
  LongYear = 'longYear',
}
const shortDate: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
};

const shortTime: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
};

export const DATE_TIME_FORMATS: Record<DateTimeFormat, Intl.DateTimeFormatOptions> = {
  [DateTimeFormat.ShortDate]: shortDate,
  [DateTimeFormat.MediumDate]: {
    dateStyle: 'medium',
  },
  [DateTimeFormat.LongDate]: {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  },
  [DateTimeFormat.ShortTime]: shortTime,
  [DateTimeFormat.MediumTime]: {
    ...shortTime,
    second: 'numeric',
  },
  [DateTimeFormat.LongTime]: {
    timeStyle: 'long',
  },
  [DateTimeFormat.ShortDateTime]: {
    ...shortDate,
    ...shortTime,
  },
  [DateTimeFormat.ShortDateTimeWithSeconds]: {
    ...shortDate,
    ...shortTime,
    second: 'numeric',
  },
  [DateTimeFormat.ShortDateTimeWithSecondsAndMs]: {
    ...shortDate,
    ...shortTime,
    second: 'numeric',
    fractionalSecondDigits: 3,
  } as Intl.DateTimeFormatOptions,
  [DateTimeFormat.MediumDateTime]: {
    dateStyle: 'medium',
    timeStyle: 'short',
  },
  [DateTimeFormat.LongDateTime]: {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  [DateTimeFormat.LongYear]: { year: 'numeric' },
};
