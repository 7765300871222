import { Directive, Input } from '@angular/core';

@Directive({
  selector: 'swiper-slide',
  standalone: true,
})
export class SwiperSlideDirective {
  @Input() controlName?: string;
  @Input() skip = false;
}
