/**
 * Remove an locale specific accents
 * @link https://stackoverflow.com/a/37511463/1444495
 * @example crème brulée => creme brulee
 */
export function removeAccents(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
export function normalizeString(str?: string): string {
  return removeAccents((str ?? '').trim().toLocaleLowerCase());
}
