import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { finalize, map, tap } from 'rxjs';
import { rethrowError } from '@examdojo/core/rxjs';
import { CategoryTopicAreaHttpService } from './category-topic-area-http.service';
import { CategoryTopicAreaStore } from './category-topic-area.store';

@Injectable({
  providedIn: 'root',
})
export class CategoryTopicAreaService {
  constructor(
    private readonly categoryTopicAreaHttpService: CategoryTopicAreaHttpService,
    private readonly store: CategoryTopicAreaStore,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  fetchCourseTopicAreas() {
    this.store.setLoading(true);
    return this.categoryTopicAreaHttpService.fetchUserCourseTree().pipe(
      map((courseTree) => courseTree.flatMap((course) => course.topic_areas)),
      tap((topicAreas) => {
        this.store.set(topicAreas);
      }),
      this.errorHandlerService.catchError(
        '[CategoryTopicAreaService]: Fetching the course tree failed',
        rethrowError(),
        {
          toast: 'An error occurred while fetching the course tree',
        },
      ),
      finalize(() => {
        this.store.setLoading(false);
      }),
    );
  }
}
