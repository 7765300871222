// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { add, addMilliseconds, intervalToDuration } from 'date-fns';
import { ExtendedUnitOfTime } from './date-time.model';

export function timeToDuration(amountOfTime: number, unit: ExtendedUnitOfTime = 'milliseconds'): Duration {
  const startOfEpoch = new Date(0, 0, 0, 0, 0, 0, 0);
  const timeAsDate =
    unit === 'milliseconds' ? addMilliseconds(startOfEpoch, amountOfTime) : add(startOfEpoch, { [unit]: amountOfTime });

  return intervalToDuration({
    start: startOfEpoch,
    end: timeAsDate,
  });
}
