import { ChangeDetectorRef, DestroyRef, Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { BaseButton } from '@examdojo/core/button';
import { markFormAsSubmitted } from '@examdojo/core/form';
import { FormConfirmFnReturnType } from '@examdojo/core/form-submit-button';
import { filter, firstValueFrom, isObservable, merge, tap, timer } from 'rxjs';
import { SwiperStepperComponent } from './swiper-stepper.component';

@Directive({
  selector: '[swiperStepperNext]',
  standalone: true,
})
export class SwiperStepperNextDirective {
  constructor(
    private readonly button: BaseButton,
    private readonly swiperStepperComponent: SwiperStepperComponent<FormGroup>,
    private readonly cdRef: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef,
  ) {
    merge(
      this.changeButtonTypeFromSlide(),
      this.disableOnActiveControlStatusChange(),
      this.swiperStepperComponent.goNextTrigger$.pipe(tap(() => this.goNextOrSubmit())),
    )
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  @Output() confirm = new EventEmitter<unknown>();

  private pending = false;

  @HostListener('click', ['$event'])
  onClick() {
    this.goNextOrSubmit();
  }

  private goNextOrSubmit() {
    const swiperInstance = this.swiperStepperComponent.swiperInstance()!;

    if (swiperInstance.isEnd) {
      this.submitForm();
      return;
    }

    const currentIndex = swiperInstance.activeIndex;
    const slides = this.swiperStepperComponent.slides();
    const nextSlideIndex = slides.findIndex((slide, index) => index > currentIndex && !slide.skip);

    if (nextSlideIndex === -1) {
      return;
    }

    timer(this.swiperStepperComponent.goNextDelayMs ?? 0)
      .pipe(
        tap(() => {
          swiperInstance.slideTo(nextSlideIndex);
        }),
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  private changeButtonTypeFromSlide() {
    return this.swiperStepperComponent.isLastStep$.pipe(
      tap((isLastStep) => {
        this.button.type.set(isLastStep ? 'submit' : 'button');
      }),
    );
  }

  private disableOnActiveControlStatusChange() {
    return this.swiperStepperComponent.activeControlStatus$.pipe(
      filter(Boolean),
      tap((status) => {
        this.button.disabled.set(status !== 'VALID');
      }),
    );
  }

  private async submitForm() {
    if (this.pending) {
      return;
    }

    this.setInProgress(true);
    const form = this.swiperStepperComponent.form();
    await markFormAsSubmitted(form);

    if (!form.valid) {
      this.setInProgress(false);
      return;
    }
    try {
      const confirmFn = this.swiperStepperComponent.submitFn();
      const asyncFnOrResult = confirmFn(form);
      let result: FormConfirmFnReturnType = asyncFnOrResult;

      if (isObservable(asyncFnOrResult)) {
        result = await firstValueFrom(asyncFnOrResult.pipe(takeUntilDestroyed(this.destroyRef)), {
          defaultValue: undefined,
        });
      } else {
        result = await asyncFnOrResult;
      }

      this.confirm.emit(result);
    } catch (error) {
      console.error(error);
    } finally {
      this.setInProgress(false);
    }
  }

  private setInProgress(pending: boolean) {
    this.button.pending = pending;
    this.pending = pending;
    this.cdRef.markForCheck();
  }
}
