import { DestroyRef, Directive, HostListener } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { BaseButton } from '@examdojo/core/button';
import { distinctUntilChanged, tap } from 'rxjs';
import { SwiperStepperComponent } from './swiper-stepper.component';

@Directive({
  selector: '[swiperStepperPrevious]',
  standalone: true,
})
export class SwiperStepperPreviousDirective {
  constructor(
    private readonly button: BaseButton,
    private readonly swiperStepperComponent: SwiperStepperComponent<FormGroup>,
    private readonly destroyRef: DestroyRef,
  ) {
    this.disableOnFirstSlide().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  @HostListener('click', ['$event'])
  onClick() {
    const swiperInstance = this.swiperStepperComponent.swiperInstance()!;
    const currentIndex = swiperInstance.activeIndex;
    const slides = this.swiperStepperComponent.slides();
    const previousSlideIndex = slides.slice(0, currentIndex).findLastIndex((slide) => !slide.skip);

    if (previousSlideIndex === -1) {
      return;
    }

    swiperInstance.slideTo(previousSlideIndex);
  }

  private disableOnFirstSlide() {
    return this.swiperStepperComponent.activeSlideIndex$.pipe(
      distinctUntilChanged(),
      tap((slideIndex) => {
        this.button.disabled.set(slideIndex === 0);
      }),
    );
  }
}
