import { Injectable } from '@angular/core';
import { CategoryTopicAreaService } from '@examdojo/category';
import { distinctUntilChanged, filter, map, merge, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserQuery } from '../user/user.query';
import { QuestionQuery } from '../question/question.query';

@Injectable({
  providedIn: 'root',
})
export class FetchCategoryTopicAreaEffectService {
  constructor(
    private readonly userQuery: UserQuery,
    private readonly questionQuery: QuestionQuery,
    private readonly categoryTopicAreaService: CategoryTopicAreaService,
  ) {
    merge(this.userCourseChange$, this.questionPresenceChange$)
      .pipe(
        switchMap(() => this.categoryTopicAreaService.fetchCourseTopicAreas()),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  readonly questionPresenceChange$ = this.questionQuery.select('question').pipe(
    map((question) => question?.id),
    filter(Boolean),
    distinctUntilChanged(),
  );

  readonly userCourseChange$ = this.userQuery.active$.pipe(
    filter(Boolean),
    map((user) => user.course_id),
    filter(Boolean),
    distinctUntilChanged(),
  );
}
